import React, { type CSSProperties, type FC } from 'react';

import { Html } from '@/components/content/html';

export type ClarInformationProps = GraphqlSelect.AboutUs<'clar'>;

const sectionStyle: CSSProperties = {
    paddingTop: 'clamp(12px, 2.7vw, 35px)',
    paddingBottom: 'clamp(12px, 2.7vw, 35px)',
};

export const ClarInformation: FC<ClarInformationProps> = ({ clar }) => {
    return (
        <section style={sectionStyle} className="clar box bg-dark c-white mt-72">
            <div className="container">
                <Html className="clar-content fs-20">
                    {`<h2 class="fs-36">${clar.title}</h2>` + clar.description.html}
                </Html>
            </div>
        </section>
    );
};
