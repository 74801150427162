import React, { type FC } from 'react';

import { Html } from '@/components/content/html';
import { RelatedFirms } from '@/components/related/related-firms';

export type OurPartnersProps = GraphqlSelect.AboutUs<'loandoPartners'>;

export const OurPartners: FC<OurPartnersProps> = ({ loandoPartners }) => {
    return (
        <section className="container mt-72">
            <Html className="fs-18">
                {`<h2 class="fs-64">${loandoPartners.title}</h2>` + loandoPartners.description.html}
            </Html>

            {loandoPartners.firms.length > 0 && <RelatedFirms render={loandoPartners.firms} />}
        </section>
    );
};
