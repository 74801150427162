import { graphql, type HeadFC, type PageProps } from 'gatsby';
import React, { type FC } from 'react';

import { ContentWithHeader } from '@/components/content/content-with-header';
import { Html } from '@/components/content/html';
import { AppHead } from '@/components/head';
import { ClarInformation, type ClarInformationProps } from '@/components/sections/about-us/clar-information';
import { OurPartners, type OurPartnersProps } from '@/components/sections/about-us/our-partners';
import { OurProjects, type OurProjectsProps } from '@/components/sections/about-us/our-projects';
import { WhyOurCompany, type WhyOurCompanyProps } from '@/components/sections/about-us/why-our-company';

interface AboutUsPageProps {
    page: GraphqlSelect.AboutUs<
        | 'id'
        | 'name'
        | 'lead'
        | 'aboutLoando'
        | 'loandoPolicy'
        | 'forYou'
        | 'howItWorks'
        | 'metaTitle'
        | 'metaDescription'
    > &
        WhyOurCompanyProps &
        ClarInformationProps &
        OurProjectsProps &
        OurPartnersProps;
}

const AboutUsPage: FC<PageProps<AboutUsPageProps>> = ({ data: { page } }) => {
    return (
        <>
            <ContentWithHeader theme="gray" breadcrumbs={[{ name: page.name }]}>
                <h1 className="fs-56">{page.name}</h1>

                {page.lead?.raw && (
                    <p
                        style={{ marginTop: 'clamp(10px, 2.5vw, 32px)' }}
                        className="fs-24"
                        dangerouslySetInnerHTML={{ __html: page.lead.raw }}
                    />
                )}
            </ContentWithHeader>

            <div style={{ alignItems: 'flex-start' }} className="fs-18 container d-flex flex-wrap mt-64 g-64">
                {[page.aboutLoando, page.forYou].map((section, id) => (
                    <Html key={id} as="section" style={{ flex: '1 1 450px' }}>
                        {`<h2 class="fs-36">${section.title}</h2>` + section.description.html}
                    </Html>
                ))}
            </div>

            <WhyOurCompany whyLoandoTitle={page.whyLoandoTitle} whyLoando={page.whyLoando} />
            <OurProjects
                internationalLoandoCopy={page.internationalLoandoCopy}
                internationalLoando={page.internationalLoando}
            />
            <ClarInformation clar={page.clar} />
            <OurPartners loandoPartners={page.loandoPartners} />

            <div style={{ alignItems: 'flex-start' }} className="fs-18 container d-flex flex-wrap mt-64 g-64">
                {[page.loandoPolicy, page.howItWorks].map((section, id) => (
                    <Html key={id} as="section" style={{ flex: '1 1 450px' }}>
                        {`<h2 class="fs-36">${section.title}</h2>` + section.description.html}
                    </Html>
                ))}
            </div>
        </>
    );
};

export const Head: HeadFC<AboutUsPageProps> = ({ data: { page }, location: { pathname } }) => {
    return (
        <AppHead
            index
            title={page.metaTitle}
            description={page.metaDescription}
            canonical={pathname}
            ogImage={{ id: page.id, type: 'pages' }}
        />
    );
};

export const query = graphql`
    query AboutUsPage {
        page: aboutUs {
            id
            metaTitle
            metaDescription
            name

            lead {
                raw
            }

            aboutLoando {
                title: titleAboutLoando

                description: descriptionAboutLoando {
                    html
                }
            }

            loandoPolicy {
                title

                description {
                    html
                }
            }

            forYou {
                title

                description {
                    html
                }
            }

            howItWorks {
                title

                description {
                    html
                }
            }

            internationalLoandoCopy {
                title

                description {
                    html
                }
            }

            internationalLoando {
                title

                description {
                    html
                }

                image {
                    remoteFile {
                        childImageSharp {
                            gatsbyImageData(width: 512)
                        }
                    }
                }
            }

            loandoPartners {
                title

                description {
                    html
                }

                firms {
                    ...RelatedFirms
                }
            }

            clar {
                title

                description {
                    html
                }
            }

            whyLoandoTitle

            whyLoando {
                title

                description {
                    html
                }
            }
        }
    }
`;

export default AboutUsPage;
