'use client';

import { navigate } from '@reach/router';
import React, { type FC, type HTMLAttributes, type ReactNode } from 'react';

import { cn } from '@/utils/class-name';
import { combineLinkWithParameters } from '@/utils/combine-link-with-parameters';

interface LinkButtonProps extends HTMLAttributes<unknown> {
    to: string;
    children: ReactNode;
}

export const LinkButton: FC<LinkButtonProps> = ({ to, className, children, ...props }) => {
    return (
        <button
            {...props}
            onClick={() => navigate(combineLinkWithParameters(to))}
            className={cn('button blue-button with-chevron-prefix w-100', className)}
        >
            {children}
        </button>
    );
};
