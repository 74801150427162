import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { type CSSProperties, type FC } from 'react';

import { Html } from '@/components/content/html';
import { OrganizationSchema } from '@/components/head/seo/schemas/organization-schema';

export type OurProjectsProps = GraphqlSelect.AboutUs<'internationalLoando' | 'internationalLoandoCopy'>;

const allProjectsStyle: CSSProperties = {
    gap: 'clamp(40px, 5.55vw, 72px)',
    marginTop: 'clamp(24px, 5.4vw, 70px)',
};

const projectStyle: CSSProperties = {
    gap: '18px clamp(24px, 5.4vw, 72px)',
};

export const OurProjects: FC<OurProjectsProps> = ({ internationalLoandoCopy, internationalLoando }) => {
    return (
        <>
            <section className="container mt-64">
                <Html className="fs-24">
                    {`<h2 class="fs-64">${internationalLoandoCopy.title}</h2>` +
                        (internationalLoandoCopy.description?.html || '')}
                </Html>

                <div style={allProjectsStyle} className="d-grid">
                    {internationalLoando.map((international, id) => (
                        <div key={id} style={projectStyle} className="d-flex flex-wrap ai-center">
                            {international.image?.remoteFile && (
                                <div
                                    style={{ flex: '1 1 250px', maxWidth: '512px' }}
                                    className={id % 2 !== 0 ? 'order-lg-last' : ''}
                                >
                                    <GatsbyImage image={getImage(international.image.remoteFile)!} alt="" />
                                </div>
                            )}

                            <div style={{ flex: '1.5 1 300px' }}>
                                <h3 className="fs-48">{international.title}</h3>

                                <Html style={{ marginTop: 'clamp(10px, 2.5vw, 32px)' }} className="fs-24">
                                    {international.description.html}
                                </Html>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <OrganizationSchema />
        </>
    );
};
